import React, { useState } from 'react'
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Input from "@material-tailwind/react/Input";
import axiosInstance from "../config/axios";
import Swal from 'sweetalert2';
import { Button } from '@material-tailwind/react';

function PlayerInformationForm({ data }) {
    //const [name, setName] = useState()
    //const [email, setEmail] = useState()
    //const [phone, setPhone] = useState()
    const [password , setPassword] = useState()
    const [upline, setUpline] = useState()
    // const [password] = useState()

    const group = JSON.parse(localStorage.getItem("group"));

    const submit = (event) => {

        const token = localStorage.getItem("access_token");

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, update it!'

        }).then((result) => {

            if (result.isConfirmed) {

                axiosInstance("set_player_det", {
                    
                    method: "POST",
                    data: {
                        ...data,
                        token: token,
                        group: group.wbid,
                        pass: password,
                        //flnm: name,
                        //emal: email,
                        //phon: phone,
                        user_id: data.uid,
                        upline: upline,
                    },
                }).then((res) => {
                    if(res.data.code === 0){

                        Swal.fire(
                            'Success!',
                            'Your data has been Updated',
                            'success'
                        )
                    }else{

                        Swal.fire(
                            'Warning!',
                            res.data.message,
                            'failed'
                        )
                    }
                    
                }).catch((err) => {

                    console.log(err)
                });

            }
        })

        event.preventDefault()
    }

    return (
        <>
            <Card >
                <form onSubmit={submit}>
                    <CardHeader size="sm" color="blueGray" contentPosition="left">
                        <div className='gap-4'>
                            <h2 className="text-white text-2xl">Information Player</h2>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <div className="grid grid-cols-1 gap-4 ">
                            <div className="">
                                <Input
                                    type="text"
                                    color="lightBlue"
                                    size="sm"
                                    outline={true}
                                    placeholder="Player ID"
                                    defaultValue={data.uid}
                                    disabled
                                />
                            </div>
                            <div className="">
                                <Input
                                    type="text"
                                    color="lightBlue"
                                    size="sm"
                                    outline={true}
                                    placeholder="Name"
                                    defaultValue={data.name}
                                    //onChange={(event) => setName(event.target.value)}
                                    disabled

                                />
                            </div>
                            
                            <div className="">
                                <Input
                                    type="text"
                                    color="lightBlue"
                                    size="sm"
                                    outline={true}
                                    placeholder="Username"
                                    defaultValue={data.user}
                                    //onChange={(event) => setName(event.target.value)}
                                    disabled
                                />
                            </div>
                            <div className="" style={{display : (group.wbid !== 1) ? 'none' : 'block'}}>
                                <Input
                                    type="text"
                                    color="lightBlue"
                                    size="sm"
                                    outline={true}
                                    placeholder="Email"
                                    defaultValue={data.emai}
                                    //onChange={(event) => setEmail(event.target.value)}
                                    disabled
                                    
                                />
                            </div>
                            <div className="" style={{display : (group.wbid !== 1) ? 'none' : 'block'}}>
                                <Input
                                    type="text"
                                    color="lightBlue"
                                    size="sm"
                                    outline={true}
                                    placeholder="Phone"
                                    defaultValue={data.phon}
                                    //onChange={(event) => setPhone(event.target.value)}
                                    disabled

                                />
                            </div>
                            <div className="">
                                <Input
                                    type="text"
                                    color="lightBlue"
                                    size="sm"
                                    outline={true}
                                    placeholder="Level"
                                    defaultValue={data.leve}
                                    disabled
                                />
                            </div>
                            <div className="">
                            {
                                ( localStorage.getItem('username') === 'master' || 
                                localStorage.getItem('username') === 'aakaudit' || 
                                localStorage.getItem('username') === 'aakwiliam' ) && 
                                <Input
                                type="text"
                                color="lightBlue"
                                size="sm"
                                outline={true}
                                placeholder="Upline username"
                                defaultValue={data.upus}
                                onChange={(event) => setUpline(event.target.value)}
                                />
                            }    
                            </div>
                            <div className="">
                                <Input
                                    type="password"
                                    color="lightBlue"
                                    size="sm"
                                    outline={true}
                                    placeholder="Password"
                                    onChange={(event) => setPassword(event.target.value)}
                                />
                            </div>
                            <div className="">
                            <Button type="submit">Save</Button>
                            </div>
                        </div>
                    </CardBody>
                </form>

            </Card>
        </>
    )
}

export default PlayerInformationForm